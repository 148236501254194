import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/login_main-img.svg";
import { setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
//import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../utils/APIGeneralService";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Col
} from "reactstrap";
import PublicLayout from '../components/PublicLayout/publicLayout';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken };
  const { setEmail } = useContext(UserContext);

  // common validation function
  const validate = () => {
    const errors = {};
    if (!emailAddress) {
      errors.email = "Email is required.";
    }
    
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCookies("forgotEmail",emailAddress)
    setLoad(true);
    if (validate()) {
      const res = await APIServices.post(
        "forgotPassword",
        {
          emailAddress
        },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
       
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => {
          navigate("/reset-password");
        }, 3000);
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, username: "" }));
    }
  };

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <PublicLayout>
    <section className="login_page_inner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-7">
            <div className="login-form">
              <h1 className="login-heading text-center font_40 font_700 mb-3">Forget Password</h1>
              <p className="login-subheading text-center font_20 font_400">
                Hey, Enter your details to Reset <br /> the password
              </p>
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup className="mt-3">
                  <label htmlFor="email" className="form-label d-none">Email address</label>
                  <Input
                    className="form-control form_input font_black_16 mb-3"
                    id="email"
                    name="email"
                    placeholder="Enter Email / Phone No"
                    type="text"
                    onChange={handleEmailChange}
                  />
                  {errors.email && (
                    <div className="errorVal mt-2">{errors.email}</div>
                  )}
                </FormGroup>
                <div className="text-center">
                  {load ? (
                    <Button className="btn primary-btn font_18 w-100 py-2 my-4">
                      Processing...
                    </Button>
                  ) : (
                    <Button className="btn primary-btn font_18 w-100 py-2 my-4" type="submit">
                      Send OTP
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div className="login-right-image">
              <img src={logo} className="img-fluid" alt="Login" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ToastContainer />
  </PublicLayout>

  );
};

export default ForgetPassword;
