import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Form,
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card, Col, Input, CardBody, CardTitle, CardText
} from "reactstrap";
import APIServicenew from "../../utils/APIGeneralService";
import { UserContext } from "../../context/UserContextProvider";


export default function SMSTemplateModal({ showdt, handleCloseDt, setTemplateId,setTemplateList, handleChooseTemplate }) {
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const { accessToken, logout } = useContext(UserContext);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken, authorization: accessToken };
  const navigate = useNavigate();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [sort, setSort] = useState("newest");
  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const colors = ['bg_lightgreen', 'bg_lightorange', 'bg_skyblue', 'bg_lightpurple','bg_lightpink','bg_lightyellow'];


  useEffect(() => {
    //initial call of fetch message data
    fetchData();
  }, [sort, keyword]);
  //open sms templates
  const fetchData = async () => {
    setLoading(true)
    try {
      const queryParams = [];
      if (keyword) queryParams.push(`keyword=${keyword}`);
      if (sort) queryParams.push(`sort_by=${sort}`);
      //call sms template list API
      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getSMSTemplates${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setTemplateData(data);
        setTemplateList(data)
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        setTemplateData([]);
        setTemplateList([])

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTemplateData([]);
      setTemplateList([])

    }
    setLoading(false)
  };

  const handleTemplateClick = (id) => {
    setSelectedTemplateId(id);
    setTemplateId(id)
  };

  return (
    <>
      <Modal
        isOpen={showdt}
        size="xl"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
        gradient="danger"
        className=" modal-dialog-centered template_msg"
      >
        <ModalHeader
          toggle={handleCloseDt}
          className="modal-header-hd justify-content-between"
        >
          <Col
            xs="3" className="fw-bold">
            Choose a Messages Template
          </Col>
          <Col
            xs="9"
            className="d-flex align-items-center justify-content-end content-inner"
          >
            <div className="select-filter-wrapper search-wrapper h-38 mb-0">
              <i class="fa-solid fa-magnifying-glass"></i>
              <Input
                className="search_message h-38 font_12"
                placeholder="Search by name"
                value={keyword}
                type="text"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div className="message-filter-wrapper select-filter-wrapper flex-nowrap  bg-gray-theme">
              <p className="font_12 w-nowrap">Short by : </p>
              <div className="position-relative d-flex gap-3">
                <Input
                  type="select"
                  class="form-select font_12"
                  aria-label="Default select example"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option selected value="newest">
                    Newest
                  </option>
                  <option value="oldest">Oldest</option>
                  {/* More country codes as needed */}
                </Input>
                <i class="fa-solid fa-angle-down"></i>
              </div>
            </div>
          </Col>
        </ModalHeader>
        <div className="modal-body modal-body-scroller">
          <div className="row">
            {templateData.length === 0 ? (
              <div className="col-12 text-center">
                <p>No templates found.</p>
              </div>
            ) : (
              templateData.map((template, index) => {
                const randomColor = colors[index % colors.length]; // Cycle through colors
                return (
                  <div className="col-md-6 col-lg-6" key={template.id}>
                    <Card
                      className={`mb-3 ${randomColor} ${selectedTemplateId === template.id ? 'card_active' : ''}`}
                      onClick={() => handleTemplateClick(template.id)}
                    >
                      <CardBody>
                        <CardTitle tag="h5">{template.text}</CardTitle>
                      </CardBody>
                    </Card>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <ModalFooter className="d-flex justify-content-start">
          <Button
            variant="primary"
            className="btn sky_btn btn btn-secondary"
            onClick={handleChooseTemplate}
          >
            Add to Your Message
          </Button>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
