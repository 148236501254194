import React, { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/login_main-img.svg";
import { setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../utils/APIGeneralService";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Col
} from "reactstrap";
import PublicLayout from '../components/PublicLayout/publicLayout';


const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);  // New state for toggling password visibility
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const { setAccessToken,accessToken } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken };
  useEffect(()=>{
    if(accessToken){
       navigate("/user/message");
    }
  })
  // common validation function

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email/email is required.";
    }
    if (!password) {
      errors.password = "Password is required.";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters.";
    } else if (password.length > 20) {
      errors.password = "Password must not exceed 20 characters.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //submit login details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    //call common validation function
    if (validate()) {
      //call login API
      const res = await APIServices.post(
        "login",
        {
          email,
          password,
        },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
        let accessToken = res?.data?.detail?.token;
        //update state for login
        setCookies("accessToken", accessToken);
        setCookies("name", res.data?.detail?.name);
        setAccessToken(accessToken);
      
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => {
          navigate("/user/message");
        }, 1000);
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

  //handle email changes
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  //handle password changes
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  //password hide show function
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggles password visibility
  };

  //show success or failed message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <PublicLayout>
      <section className="login_page_inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-7">
              <div className="login-form">
                <h1 className="login-heading text-center font_40 font_700 mb-3">Sign in</h1>
                <p className="login-subheading text-center font_20 font_400">
                  Hey, Enter your details to login <br /> to your account
                </p>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup className="mt-3">
                    <label htmlFor="email" className="form-label d-none">Email address</label>
                    <Input
                      className="form-control form_input font_black_16 mb-3"
                      id="email"
                      name="email"
                      placeholder="Enter Email / Phone No"
                      type="text"
                      onChange={handleEmailChange}
                    />
                    {errors.email && (
                      <div className="errorVal mt-2">{errors.email}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password" className="form-label d-none">Password</label>
                    <div className="position-relative">
                      <Input
                        className="form-control form_input font_black_16 password-input"
                        placeholder="Passcode"
                        type={showPassword ? "text" : "password"} // Toggle between text and password
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        maxLength={20}
                        onChange={handlePasswordChange}
                      />
                      <span
                        className="hide-text font_12"
                        onClick={togglePasswordVisibility} // Toggle password visibility on click
                        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                      >
                        {showPassword ? "Hide" : "Show"} {/* Toggle button text */}
                      </span>
                    </div>
                    {errors.password && (
                      <div className="errorVal mt-2">{errors.password}</div>
                    )}
                  </FormGroup>
                  <div className="reset-pass text-end mt-2">
                    <Link className="font_12 text-decoration-underline" to="/forgot-password">
                      Reset Password
                    </Link>
                  </div>

                  <div className="text-center">
                    {load ? (
                      <Button className="btn primary-btn font_18 w-100 py-2 my-4">
                        Processing...
                      </Button>
                    ) : (
                      <Button className="btn primary-btn font_18 w-100 py-2 my-4" type="submit">
                        Sign in
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="login-right-image">
                <img src={logo} className="img-fluid" alt="Login" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer/>
    </PublicLayout>
  );
};

export default LoginPage;
