import { useContext, useEffect, useRef, useState } from "react";
import AuthLayout from '../components/PublicLayout/authLayout';
import { useNavigate, Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Form
} from "reactstrap";
import PaginatedTable from "../components/Table/PaginatedTable";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import "react-toastify/dist/ReactToastify.css";
import {
  capitalizeFirstLetter
} from "../utils/Helper";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";

const EditProfile = (props) => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [data, setData] = useState([]);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [backupMobileNumber, setBackupMobileNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [backupCountryCode, setBackupCountryCode] = useState(null);
  const [load, setLoad] = useState(false);
  const { setAccessToken } = useContext(UserContext);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    //call get profile function 
    getProfile();
  }, []);

  //common validator function
  const validate = () => {
    const errors = {};
    if (!mobileNumber) {
      errors.mobileNumber = "Phone Number is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //handle submit data 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    //if (validate()) {
    //make payload for api
    let payload = {
      email: email,
      phone_number: mobileNumber,
      country_code: countryCode,
      backup_phone_number: backupMobileNumber,
      backup_country_code: backupCountryCode ? backupCountryCode : "+1",
    }
    //call update profile API
    const res = await APIServices.post(
      "updateProfile",
      payload,
      headers
    );
    if (res?.data?.status === true) {
      setLoad(false);
      showToast(res?.data?.message || "Success", "success");
    } else {
      showToast(res?.data?.message || "Something went wrong.", "error");
      setLoad(false);
    }
    //}
    setLoad(false);
  };

  //get profile 
  const getProfile = async () => {
    try {
      //call get profile API
      const response = await APIServices.get(
        `getProfile`,
        headers
      );
      if (response?.status == 200) {
        const resp = response?.data?.detail || [];
        //set all state for show data 
        setData(resp);
        setEmail(resp?.email);
        setMobileNumber(resp?.phonenumber);
        setCountryCode(resp?.phonenumberprefix);
        setBackupCountryCode(resp?.backup_prefix);
        setBackupMobileNumber(resp?.backup_phone_number);
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //show success or failed message

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };



  return (
    <AuthLayout>
      <div className="content-inner schedule-messaging">
        <div className="overflow-content">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 card-title">Edit Profile</h3>

                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmit}>
                  {/* First Row: Phone Numbers */}
                  <Row>
                    <Col md="6">
                      <FormGroup className="mt-3">
                        <label htmlFor="phoneNumber" className="form-label">Phone *</label>
                        <InputGroup className="form_input d-flex align-items-center">
                          <InputGroupAddon addonType="prepend">
                            <Input
                              type="select"
                              value={countryCode ?? data?.phonenumberprefix}
                              className="form-control font_black_16 country-code"
                              style={{ maxWidth: '120px' }}
                              onChange={(e) => setCountryCode(e.target.value)}

                            >
                              <option value="+1">US +1</option>
                              <option value="+91">IN +91</option>
                              <option value="+44">UK +44</option>
                              <option value="+61">AUS +61</option>
                              {/* More country codes as needed */}
                            </Input>
                          </InputGroupAddon>
                          <Input
                            className="form-control  font_black_16"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter Phone No"
                            type="tel"
                            value={mobileNumber ?? data?.phonenumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            maxLength={10}
                            style={{ border: 'none' }}
                          />
                        </InputGroup>
                        {errors.phoneNumber && <div className="errorVal mt-2">{errors.phoneNumber}</div>}
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="mt-3">
                        <label htmlFor="backupPhoneNumber" className="form-label">Backup Phone *</label>
                        <InputGroup className="form_input d-flex align-items-center">
                          <InputGroupAddon addonType="prepend">
                            <Input
                              type="select"
                              value={backupCountryCode ?? data?.backupCountryCode}
                              className="form-control font_black_16 country-code"
                              style={{ maxWidth: '120px' }}
                              onChange={(e) => setBackupCountryCode(e.target.value)}
                            >
                              <option value="+1">US +1</option>
                              <option value="+91">IN +91</option>
                              <option value="+44">UK +44</option>
                              <option value="+61">AUS +61</option>
                              {/* More country codes as needed */}
                            </Input>
                          </InputGroupAddon>
                          <Input
                            className="form-control  font_black_16"
                            id="backupPhoneNumber"
                            name="backupPhoneNumber"
                            placeholder="Enter Backup Phone No"
                            type="tel"
                            value={backupMobileNumber ?? data?.backup_phone_number}
                            onChange={(e) => setBackupMobileNumber(e.target.value)}
                            maxLength={10}
                            style={{ border: 'none' }}
                          />
                        </InputGroup>
                        {errors.backupPhoneNumber && <div className="errorVal mt-2">{errors.backupPhoneNumber}</div>}
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* Second Row: Email */}
                  <Row>
                    <Col md="6">
                      <FormGroup className="mt-3">
                        <label htmlFor="email" className="form-label">Email *</label>
                        <Input
                          className="form-control form_input font_black_16 mb-3"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                          type="email"
                          value={email ?? data?.email}
                          onChange={(e) => setEmail(e.target.value)}
                          readOnly
                        />
                        {errors.email && <div className="errorVal mt-2">{errors.email}</div>}
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* Button Row */}
                  <Row className="justify-content-center">
                    <Col md="6" className="text-center">
                      {load ? (
                        <Button className="btn primary-btn" disabled>
                          Processing...
                        </Button>
                      ) : (
                        <Button className="btn primary-btn" type="submit">
                          Save Profile
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
      <ToastContainer />
    </AuthLayout>

  );
};

export default EditProfile;
