// Header.js
import React from 'react';
import logo from "../../assets/images/logo.png";
//COMMON HEADER COMPONENT
const Header = () => {
  return (
    <header>
      <div className="container-fluid">
        <nav className="px-5">
          <div className="row align-items-center justify-content-between">
            <div className="col-4">
              <a className="navbar-brand" href="#">
                <img src={logo} className="img-fluid main-logo" alt="logo" />
              </a>
            </div>
            <div className="col-8">
              <ul className="header-right nav align-items-center justify-content-end">
                <li className="lang-wrapper nav-item">
                  <img src="/images/lang-select-icon.png" alt="Language Icon" />
                  <select className="form-select lang-select font_black_16" aria-label="Language select">
                    <option selected>Eng</option>
                    <option value="1">Hin</option>
                  </select>
                </li>
                <li className="nav-item">
                  <a href="#" className="secondary-btn request-btn font_white_16">
                    Request Demo
                    <img src="images/right-up-arrow.png" className="img-fluid" alt="arrow" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
