import { Link } from "react-router-dom";
import { Navbar, NavItem, NavLink, Nav, Button, Col, Card, CardBody, Progress } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const AuthNavbar = () => {
  const location = useLocation(); // Get the current location

  return (
    <div className="">
      {/* Sidebar */}
        <Navbar color="light" light className="flex-column bg-white p-0">
          {/* <NavItem className="w-100 my-3"> */}
            <NavLink href="#" className="text-center py-2">
              {/* <h3>Logo Here</h3> */}
              <img src='/images/logo.png'  className='main-logo img-fluid' alt='logo image' />
            </NavLink>
          {/* </NavItem> */}
          <div className="menu-plan-wrapper d-flex flex-column justify-content-between">
              <Nav vertical className="w-100 sidebar-menu">
                <NavItem>
                  <NavLink href="/user/dashboard" className={location.pathname.includes('dashboard') ? 'nav-link active font_black_16' : 'nav-link font_black_16'}>
                    <img src='/images/dashboard_icon.png' className='img-fluid side-nav-icon' alt='icon'/>
                    Dashboard
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/user/message" className={location.pathname.includes('message') ? 'nav-link active font_black_16' : 'nav-link font_black_16'}>
                    <img src='/images/email_icon.png' className='img-fluid side-nav-icon' alt='icon'/>
                    Messages Configurator
                    
                  <i class="fa-solid fa-angle-right"></i>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/user/storage" className={location.pathname.includes('storage') ? 'nav-link active font_black_16' : 'nav-link font_black_16'}>
                  <img src='/images/storage_icon.png' className='img-fluid side-nav-icon' alt='icon'/>
                  Storage</NavLink>
                </NavItem>
                <NavItem>
                   <NavLink href="/user/profile" className={location.pathname.includes('profile') ? 'nav-link active font_black_16' : 'nav-link font_black_16'}>
                  <img src='/images/profile_icon.png' className='img-fluid side-nav-icon' alt='icon'/>
                  Profile</NavLink>
                </NavItem>
              </Nav>
              <div className='sidebar-plan text-center'>
                  <Card className="bg-primary-theme">
                    <CardBody>
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <h5 className='font_black_14'>Your Plan</h5>
                        <p className="font_black_14">0/50 used</p>
                        </div>
              
                      <Progress value={50} className="mb-2" />
                      <p className="font_black_14 py-2 px-4">Upgrade to PRO to get access all Features!</p>
                      <Button color="primary" className='primary-btn w-100 rounded-pill font_white_14 font_700 plan-btn' block>Get Pro Now!</Button>
                    </CardBody>
                  </Card>
                  <div className='plan-help'>
                  <p className="font_12"><span>Need help?</span> Contact Us</p>
                  <p className="font_12"><span>or email at</span><a href='mailto:example@abc.com' className='email-link ms-1'>example@abc.com</a></p>
                  </div>
                </div>
            </div>


        </Navbar>
    </div>
  );
};

export default AuthNavbar;

