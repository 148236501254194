import React, { useContext, useEffect, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container } from "reactstrap";

import Message from "../pages/message.js";
import ScheduleMessage from "../pages/ScheduleMessage.js";
import Profile from "../pages/profile.js";
import EditProfile from "../pages/editProfile.js";

import APIServicenew from "../utils/APIGeneralService.js";

//this layout use for side bar

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const fullPath = window.location.pathname;
  let pathname = fullPath.substring(fullPath.lastIndexOf("/") + 1);

  return (
    <>
        <Routes>
          <Route path="/dashboard" element={<Message />} exact />
          <Route path="/message" element={<Message />} exact />
          <Route path="/schedule-message" element={<ScheduleMessage />} exact />
          <Route path="/storage" element={<Message />} exact />
          <Route path="/profile" element={<Profile />} exact />
          <Route path="/edit-profile" element={<EditProfile />} exact />

        </Routes>

    </>
  );
};

export default Admin;
