import React from 'react';
import { Navbar, NavItem } from 'reactstrap';

//FOOTER AFTER LOGIN
const AuthFooter = () => {
  return (
    <Navbar color="light" light className="border-top py-3">
      <NavItem className="text-center w-100">
        <p className="small mb-0">© 2024 Proxy. All rights reserved.</p>
      </NavItem>
    </Navbar>
  );
};

export default AuthFooter;
