import { useContext, useEffect, useRef, useState } from "react";
import AuthLayout from "../components/PublicLayout/authLayout";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import PaginatedTable from "../components/Table/PaginatedTable";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../utils/Helper";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../components/Modal/deleteModal";

const Message = (props) => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [limit, setLimit] = useState(10);
  const [data1, setData1] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [fullname, setFullname] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [reset, setReset] = useState(false);
  const offset = (currentPage - 1) * limit;
  const [sort, setSort] = useState("newest");
  const [showDt, setShowDt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDeleteMsg, setSelectedDeleteMsg] = useState(null);

  //get list of message from DB
  const fetchData = async (page = 1) => {
    const skip = page;
    setLoading(true)
    try {
      //add filtets
      const queryParams = [];
      if (keyword) queryParams.push(`keyword=${keyword}`);
      if (limit) queryParams.push(`limit=${limit}`);
      if (skip) queryParams.push(`offset=${skip}`);
      if (sort) queryParams.push(`sort_by=${sort}`);
      //call message list API
      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getMessage${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setData1(data);
        setCount(response?.data?.count);
        const totalItems = response?.data?.count || 0;
        setTotalCount(Math.ceil(totalItems / limit));
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        setData1([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
      setTotalCount(0);
    }
    setLoading(false)

  };

  useEffect(() => {
    //initial call of fetch message data
    fetchData(currentPage);
  }, [currentPage, limit, sort, keyword]);

  //make coloums for message list table
  const columns = [
    {
      dataField: "index",
      text: "S.No.",
      formatter: (cell, row, rowIndex) => {
        const page = currentPage; // Replace with the actual way to get the current page number
        const sizePerPage = limit; // Replace with the actual way to get rows per page
        return (page - 1) * sizePerPage + rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Contact Name",
      formatter: (cell, row) => capitalizeFirstLetter(row.name),
    },
    {
      dataField: "mobile_number",
      text: "Phone Number",
      formatter: (cell, row) =>
        "(" + row.country_code + ")" + " " + row.mobile_number,
    },

    {
      dataField: "schedule_date",
      text: "Schedule Date",
      formatter: (cell, row) => moment(row.schedule_date).format("MMM DD YYYY"),
    },

    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <Button
          size="sm"
          className={
            row.status == 0
              ? "pending_btn"
              : row.status == 1
              ? "sent_btn"
              : "failed_btn"
          }
        >
          {row.status === 0 ? "Pending" : row.status === 1 ? "Sent" : "Failed"}
        </Button>
      ),
    },
    {
      // dataField: "schedule_date",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <>
            {row?.is_sent == 0 ? (
              <>
                <span
                  onClick={() =>
                    navigate("/user/schedule-message", { state: row })
                  }
                >
                  <i class="fa-solid fa-pencil"></i>
                </span>
                <span
                  className="ms-3"
                  onClick={() => {
                    setSelectedDeleteMsg(row?.id);
                    setShowDt(true);
                  }}
                >
                  <i class="fa-regular fa-trash-can"></i>
                </span>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (reset) {
      fetchData();
      setCurrentPage(1);
      setReset(false);
    }
  }, [reset]);

  //handle delete message 
  const handleDelete = async () => {
    try {
      if (selectedDeleteMsg !== null) {
        const payload = { id: selectedDeleteMsg };
        //call delete message api
        const res = await APIServices.post(`/deleteMessage`, payload, headers);
        if (res?.status === 200) {
          showToast(res?.data?.message || "Success", "success");
          setSelectedDeleteMsg(null);
          fetchData();
          setCurrentPage(1);
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      }
      setShowDt(false);
    } catch (error) {
      console.error("Error deleting Message:", error);
    }
  };

  //show success and fail message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <AuthLayout>
      <div className="content-inner messaging">
        <div className="overflow-content">
          <Card className="shadow">
            <CardHeader className="border-0 bg-white p-0">
              <Row className="align-items-center">
                <Col xs="3">
                  <h3 className="mb-0 card-title font_22">
                    Latest Schedule messages{" "}
                  </h3>
                </Col>
                <Col
                  xs="9"
                  className="d-flex align-items-center justify-content-end"
                >
                  <Link
                    className="btn btn-primary btn-block h-38 font_14"
                    to="/user/schedule-message"
                  >
                    Schedule a Message!
                  </Link>
                  <div className="select-filter-wrapper search-wrapper h-38 mb-0">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <Input
                      className="search_message h-38 font_12"
                      placeholder="Search by name"
                      value={keyword}
                      type="text"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                  <div className="message-filter-wrapper select-filter-wrapper flex-nowrap  bg-gray-theme">
                    <p className="font_12 w-nowrap">Short by : </p>
                    <div className="position-relative d-flex gap-3">
                      <Input
                        type="select"
                        class="form-select font_12"
                        aria-label="Default select example"
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option selected value="newest">
                          Newest
                        </option>
                        <option value="oldest">Oldest</option>
                        {/* More country codes as needed */}
                      </Input>
                      <i class="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </Col>

                <div className="col"></div>
              </Row>
              <p className="font_black_14 green-clr mt-2">Active Members</p>
            </CardHeader>

            <div className="select-filter-wrapper"></div>
            {/* {data1 && Array.isArray(data1) && data1.length !== 0 ? ( */}
            {loading==false ? ( 
              <PaginatedTable
                data={data1}
                columns={columns}
                totalPages={totalCount}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                limit={limit}
                setLimit={setLimit}
              />
            ) : (
              <h2 className="text-center mb-4">Loading...</h2>
            )}
          </Card>
        </div>
      </div>

      <DeleteModal
        showdt={showDt}
        handleCloseDt={() => setShowDt(false)}
        handledelete={handleDelete}
      />
    </AuthLayout>
  );
};

export default Message;
