import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
// import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Form,
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
export default function DeleteModal({ showdt, handleCloseDt, handledelete }) {
  return (
    <>
      <Modal
        isOpen={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
        gradient="danger"
        className=" modal-dialog-centered"
      >
        <ModalHeader
          toggle={handleCloseDt}
          className="modal-header-hd justify-content-between"
        >
          Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete ?</ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            color="danger"
            variant="primary"
            className="success_btn"
            onClick={handledelete}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
