// Footer.js
import React from 'react';

//COMMON FOOTER FILE
const Footer = () => {
  return (
    <footer className="bg-white text-center">
      <div className="container">
        <div className="copyright">
        <p className="small mb-0">© 2024 Proxy. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
