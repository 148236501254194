import React from 'react'
import Header from '../Headers/Header'
import Footer from '../Footers/Footer'
import { Card, Col, Container, Row } from 'reactstrap'

export default function PublicLayout({children}) {
  return (<>
    <Header />
    {children}
    <Footer />
    </>
  )
}
