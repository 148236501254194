import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/login_main-img.svg";
import { getCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../utils/APIGeneralService";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Col
} from "reactstrap";
import PublicLayout from '../components/PublicLayout/publicLayout';

const Resetpassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);  // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  // State for confirm password visibility
  const [showOtp, setShowOtp] = useState(false);  // State for OTP visibility
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const { setAccessToken } = useContext(UserContext);
  const { email } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken };
  const [isLoading, setIsLoading] = useState(false);
  
  // common validation function
  const validate = () => {
    const errors = {};
   
    if (!password) {
      errors.password = "Password is required.";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters.";
    } else if (password.length > 20) {
      errors.password = "Password must not exceed 20 characters.";
    }

    if (!otp) {
      errors.otp = "OTP is required.";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    }  else if (password != confirmPassword) {
      errors.confirmPassword = "Password and confirm password dose not match.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //handle reset password submit
  const handleSubmit = async (e) => {
  
    let email = getCookies("forgotEmail");
    e.preventDefault();
    setLoad(true);
    //call common validation function
    if (validate()) {
      //call reset password API
      const res = await APIServices.post(
        "resetPassword",
        {
          email,
          password,
          otp
        },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

//set hide show function
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const toggleOtpVisibility = () => setShowOtp(!showOtp);

  //show success and error message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <PublicLayout>
      <section className="login_page_inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-7">
              <div className="login-form">
                <h1 className="login-heading text-center font_40 font_700 mb-3">Reset Password</h1>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup>
                    <label htmlFor="password" className="form-label d-none">New Password</label>
                    <div className="position-relative mt-3">
                      <Input
                        className="form-control form_input font_black_16"
                        placeholder="New Password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        maxLength={20}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="hide-text font_12"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </span>
                    </div>
                    {errors.password && <div className="errorVal mt-2">{errors.password}</div>}
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="confirm_password" className="form-label d-none">Confirm New Password</label>
                    <div className="position-relative mt-3">
                      <Input
                        className="form-control form_input font_black_16"
                        placeholder="Confirm New Password"
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirm_password"
                        name="confirm_password"
                        maxLength={20}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span
                        className="hide-text font_12"
                        onClick={toggleConfirmPasswordVisibility}
                        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                      >
                        {showConfirmPassword ? "Hide" : "Show"}
                      </span>
                    </div>
                    {errors.confirmPassword && <div className="errorVal mt-2">{errors.confirmPassword}</div>}
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="otp" className="form-label d-none">OTP</label>
                    <div className="position-relative mt-3">
                      <Input
                        className="form-control form_input font_black_16"
                        placeholder="Enter OTP"
                        type={showOtp ? "text" : "password"}
                        id="otp"
                        name="otp"
                        maxLength={6}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <span
                        className="hide-text font_12"
                        onClick={toggleOtpVisibility}
                        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                      >
                        {showOtp ? "Hide" : "Show"}
                      </span>
                    </div>
                    {errors.otp && <div className="errorVal mt-2">{errors.otp}</div>}
                  </FormGroup>
                  <div className="text-center">
                    {load ? (
                      <Button className="btn primary-btn font_18 w-100 py-2 my-4">
                        Processing...
                      </Button>
                    ) : (
                      <Button className="btn primary-btn font_18 w-100 py-2 my-4" type="submit">
                        Change Password
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="login-right-image">
                <img src={logo} className="img-fluid" alt="Login" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </PublicLayout>
  );
};

export default Resetpassword;
