import { React, useContext,useState } from "react";
import { useLocation } from 'react-router-dom';
import { UserContext } from "../../context/UserContextProvider";
import { Navbar, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const AuthHeader = () => {

//auth header user after login
  const location = useLocation(); // Get the current location
  const { isLoggedIn, logout,data } = useContext(UserContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  let heading = "";
  // Determine the heading based on the pathname
  if (location.pathname.includes('message')) {
    heading = 'Messages';
  } else if (location.pathname.includes('dashboard')) {
    heading = 'Dashboard';
  } else if (location.pathname.includes('storage')) {
    heading = 'Storage';
  } else if (location.pathname.includes('profile')) {
    heading = 'Profile';
  }
  //CALL logout function
  const callLogout = async (e) => {
    logout()
  };

 
  return (
    <div className='header'>
      {/* Header */}
      <Navbar color="white" light className="">
      <NavItem>
        <h4 className="mb-0 font_20 font_700">{heading}</h4>
      </NavItem>
      <NavItem className="profile-account">
        <span className="mr-2 font_18">Hello {data?.first_name} 👋🏼</span>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle className="drop-arrow">
            <i className="fa-solid fa-angle-down"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={callLogout}>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavItem>
    </Navbar>
      </div>
  );
};

export default AuthHeader;
