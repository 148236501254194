import axios from "axios";

//this file have all four methode for call API GET,POST,PUT,DELETE
class APIServicenew {
  constructor(url) {
    this.service = axios.create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      }
    });
  }
  //for get method API call
  async get(path, headers = { "content-type": "application/json" }) {
    try {
      //call node API
      const response = await this.service.get(path, {
        headers: headers,
      });
      return { status: response.status, data: response.data };
    } catch (error) {
      console.log("ERROR", error);
      return error;
    }
  }
//for post method API call
  async post(
    path,
    payload,
    headers = { "content-type": "application/json" },
    onUploadProgress
  ) {
    try {
      //call node API
      const response = await this.service.request({
        method: "POST",
        url: path,
        // responseType: "json",
        data: payload,
        headers: headers,
        // onUploadProgress: onUploadProgress
      });
      return response;
    } catch (error) {
      return { status: error?.response?.status, data: error?.response?.data };
    }
  }
//for put method API call
  async put(
    path,
    payload,
    headers = { "content-type": "application/json" },
    onUploadProgress
  ) {
    try {
      //call node API
      const response = await this.service.request({
        method: "PUT",
        url: path,
        responseType: "json",
        data: payload,
        headers: headers,
        onUploadProgress: onUploadProgress,
      });
      return response;
    } catch (error) {
      return { status: error?.response?.status, data: error?.response?.data };
    }
  }
//for delete method API call
  async delete(
    path,
    headers = { "content-type": "application/json" },
    payload = {}
    // payload
  ) {
    try {
      //call node API
      const response = await this.service.request({
        method: "DELETE",
        url: path,
        headers: headers,
        data: payload,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}

export default APIServicenew;
