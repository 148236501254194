import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContextProvider";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import "@fortawesome/fontawesome-free/css/all.min.css";




const RequireAuth = ({ children }) => {
  const { accessToken } = useContext(UserContext);

  if (!accessToken) {
    return <Navigate to="/" replace/>;
  }
  

  return children;
};

const AppRouter = () => {
  const { accessToken } = useContext(UserContext);
  useEffect(() => {
    document.title = "Proxy";
  }, []);


  return (
    <Routes>
        {/* route after login */}
        <Route path="/user/*" element={<RequireAuth >
          <AdminLayout />
        </RequireAuth>} />
        {/* Route befor login */}
        <Route path="/*" element={<AuthLayout />} />
    </Routes>
  );
};

export default AppRouter;


