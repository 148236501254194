import logo from './logo.svg';
import './App.css';
import { UserContextProvider } from "./context/UserContextProvider";
import AppRouter from "./AppRouter";
import './assets/css/bootstrap.min.css';
import './assets/css/responsive.css';
import './assets/css/style.css';
import { BrowserRouter } from 'react-router-dom';
const App = () => {
  return (
    <div className="App">
       <UserContextProvider>
      <BrowserRouter>
          <AppRouter />
      </BrowserRouter>
      </UserContextProvider>
    </div>
  );
}

export default App;
